// Главный блок
.main {
  min-height: 690px;

  @media(max-width: $xl) {
    & {
      min-height: 550px;
    }
  }

  @media(max-width: $md) {
    & {
      min-height: 400px;
    }
  }

  @media(max-width: $sm) {
    &__image {
      object-position: -70px;
    }
  }
}

// Преимущества
.advantages {
  &__pack {
    max-width: 94px;
  }

  @media(min-width: $lg) {
    &-wrapper {
      transform: none !important;
    }
  }
}

// Компания
.company {
  &__picture {
    max-width: 420px;
  }

  &__pack {
    right : 16px;
    bottom: 16px;
  }

  &__content {
    max-width: 660px;
  }

  &-dialog {
    &__pack {
      max-width: 230px;
    }
  }

  @media(max-width: $xl) {
    &-dialog {
      &__content {
        flex-direction: column;
        align-items   : flex-start;
      }
    }
  }

  @media(max-width: $lg) {
    & {
      flex-direction: column;
      align-items   : flex-start;
    }

    &__picture {
      max-width: 320px;
    }

    &__pack {
      right : 8px;
      bottom: 8px;
    }
  }
}

// Контакты
.contacts {
  &__pack {
    transform   : skew(-15deg);
    border-left : 16px solid var(--color-white);
    border-right: 16px solid var(--color-white);
    max-width   : 1000px;
    left        : 51%;

    & .image {
      transform: skew(15deg) scale(1.1);
    }
  }

  &__content {
    max-width: 550px;
  }

  @media(max-width: $xl) {
    &__pack {
      left: 58%;
    }
  }
}

// Компания
.about {
  &__picture {
    max-width: 420px;
  }

  &__pack {
    right : 16px;
    bottom: 16px;
  }

  &__content {
    max-width: 660px;
  }

  &-dialog {
    &__pack {
      &::before {
        padding-top: 25%;
      }
    }
  }

  @media(max-width: $lg) {
    & {
      flex-direction: column;
      align-items   : flex-start;
    }

    &__picture {
      max-width: 320px;
    }

    &__pack {
      right : 8px;
      bottom: 8px;
    }
  }

  @media(max-width: $md) {
    &-dialog {
      &__pack {
        &::before {
          padding-top: 40%;
        }
      }
    }
  }
}

// Успех
.success {
  background-image: linear-gradient(180deg, var(--color-primary-70) 0%, var(--color-none) 100%);
}