// Логотип
.logo {
  width: 213px;

  @media(max-width: $xl) {
    & {
      width: 180px;
    }
  }


  @media(max-width: $xl) {
    & {
      width: 120px;
    }
  }
}

// Прогресс прокрутки страницы
.progress-line {
  top   : 0;
  left  : 0;
  right : 0;
  height: 3px;
}

// Шапка документа
.header {

  @media(max-width: $lg) {
    position : sticky;
    top      : 0;
    left     : 0;
    right    : 0;
    transform: translateY(var(--top));
  }
}

// Мобильное меню
.mobile {
  inset: 0;

  &__content {
    max-width       : 375px;
    background-color: var(--color-white-90);
    backdrop-filter : blur(4px);
    box-shadow      : 0 0 0 100vw var(--color-black-50);
  }

  &:not([data-mobile="open"]) {
    opacity   : 0;
    visibility: hidden;
  }

  &:not([data-mobile="open"]) &__content {
    transform : translateX(-100%);
    opacity   : 0;
    visibility: hidden;
  }

  &:not([data-mobile="open"]) &__head {
    transform: translateY(-80px);
  }

  &:not([data-mobile="open"]) &__nav {
    transform: translateX(-220px);
    opacity  : 0;
  }

  &:not([data-mobile="open"]) &__footer {
    transform: translateY(-50px);
    opacity  : 0;
  }

  &[data-mobile="open"] {
    opacity   : 1;
    visibility: visible;
  }

  &[data-mobile="open"] &__content {
    transform : translateX(0);
    opacity   : 1;
    visibility: visible;
  }

  &[data-mobile="open"] &__head {
    transform: translateY(0);
  }

  &[data-mobile="open"] &__nav {
    transform: translateX(0);
    opacity  : 1;
  }

  &[data-mobile="open"] &__footer {
    transform: translateY(0);
    opacity  : 1;
  }
}

// Навигация
.nav {
  top             : 0;
  left            : 0;
  right           : 0;
  background-image: linear-gradient(to right, var(--color-primary) 67%, var(--color-black) 33%);

  &__items {
    max-width: 850px;
  }

  @media(min-width: 2000px) {
    & {
      background: var(--color-black);
    }
  }

  @media(max-width: 1600px) {
    & {
      background-image: linear-gradient(to right, var(--color-primary) 73%, var(--color-black) 27%);
    }
  }

  @media(max-width: $xl) {
    &__items {
      max-width: 700px;
    }
  }
}

// Карта
.map {
  height: 550px;

  @media(max-width: $lg) {
    & {
      height: 450px;
    }
  }

  @media(max-width: $md) {
    & {
      height: 350px;
    }
  }
}

// Подвал
.footer {

  @media(max-width: $md) {
    & {
      flex-direction: column;
      align-items   : flex-start;
    }
  }
}