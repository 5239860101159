// Поле
.input {
  flex-grow       : 1;
  width           : 100%;
  height          : var(--size-lg);
  background-color: var(--color-white);
  font-size       : var(--text-16);
  padding         : 14px 16px;
  border          : 1px solid var(--color-black-10);
  transition      : 0.2s ease;
  user-select     : initial;

  &-cover {
    display: flex;
    width  : 100%;
  }

  &--textarea {
    height: auto;
  }

  &--error {
    border-color: var(--color-primary);
  }

  &:not(&--error):focus {
    border-color: var(--color-black-40);
  }

  &::placeholder {
    color: var(--color-black-30);
  }

  &:disabled {
    pointer-events: none;
    opacity       : 0.5;
  }
}