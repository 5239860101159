// Тело документа
body {
  color           : var(--color-black);
  background-color: var(--color-white);
}

// Выделение
::selection {
  color           : var(--color-white);
  background-color: var(--color-primary);
}