// Карточка
.card {
  display         : flex;
  flex-direction  : column;
  position        : relative;
  background-color: var(--color-white);
  box-shadow      : 0 10px 20px var(--color-black-10);
  border-radius   : 4px;
  transition      : box-shadow 0.2s ease;
  overflow        : hidden;

  &-content {
    display       : flex;
    flex-direction: column;
    flex-grow     : 1;
  }

  @media(hover) {
    &:hover {
      box-shadow: 0 10px 20px var(--color-black-30);
    }
  }
}